.locationDropdown {
  cursor: pointer;
    .blackBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #e5e6e7;
      border-radius: 10px;
      height: 35px;
      padding: 0 2rem;
      margin: 1rem 0;
    //   font-weight: 400;
      font-size: 15px;
      color: #7d8185;
    }
  
    .disabledSelectMain {
      border-color: #bababa;
      color: #bababa;
    }

    .goIcon{
        width: 14px;
        margin-top: 8px;
    }
  
    .dropdownPopup {
      background-color: #ffff;
      margin-bottom: 2rem;
    //   padding: 1rem 4rem 2rem 4rem;
      display: none;
      border-radius: 10px;
  
      .blackBox {
        margin: 0.5rem 0;
      }
      .dropdownPopup__closeIcon {
        margin-bottom: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        // font-weight: 400;
        font-size: 40px;
        height: 25px;
        color: #7d8185;
        i {
          cursor: pointer;
        }
        .IoCloseIcon{
            width: 25px;
        }
      }
  
      .dropdownPopup__search {
        height: 35px;
        input {
          flex-grow: 1;
          height: 100%;
          border: none;
          font-size: 15px;
          &::placeholder {
            color: #7d8185;
          }
  
          &:focus {
            outline: none;
          }
        }
        .IoSearchIcon{
            margin-top: 8px;
        }
      }
  
      .dropdownPopup__locationList {
        max-height: 280px;
        overflow-y: scroll;
  
        .dropdownPopup__locationList__empty{
          color: #7d8185;
          font-size: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 280px;
        }
        
  
        .dropdownPopup__location {
          height: 35px;
          cursor: pointer;
          span {
            font-size: 15px;
          }
        }
      }
    }
  
    .dropdownPopup__show {
      display: block;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .locationDropdown {
      cursor: pointer;
      .dropdownPopup {
        padding: 1rem 2rem;
  
        .dropdownPopup__search {
          input {
            flex-grow: unset;
            font-size: 15px;
            max-width: 80%;
          }
        }
  
        .dropdownPopup__locationList {
          .dropdownPopup__location {
            span {
              font-size: 15px;
            }
          }
        }
      }
  
      .dropdownPopup__show {
        display: block;
      }
    }
  }
  