.stats-list {
	td {
		text-align: center;
	}
}
.full-width {
	width: 100% !important;
}

td {
	.form-control {
		line-height: 34px !important;
	}
	::-webkit-input-placeholder {
		font-size: 11px;
		// line-height: 34px !important;
	}
	::-moz-placeholder {
		font-size: 11px;
		// line-height: 34px !important;
	}
	:-ms-input-placeholder {
		font-size: 11px;
		// line-height: 34px !important;
	}
	::placeholder {
		font-size: 11px;
		// line-height: 34px !important;
	}
	input,
	select {
		background: rgba(63, 59, 59, 0.05) !important;
		border: 1px solid #ccacac !important;
		color: rgba(0, 0, 0, 0.8) !important;
		line-height: 34px !important;
		border-radius: 1px;
		display: block;
		padding: 6px 12px;
		transition: border-color 0.15s ease-in-out 0s,
			box-shadow 0.15s ease-in-out 0s;
		width: 100%;
		font-size: 14px;

		&::-webkit-input-placeholder {
			color: rgba(32, 32, 32, 0.8) !important;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: rgba(0, 0, 0, 0.8) !important;
			opacity: 1;
		}

		&::-ms-input-placeholder {
			color: rgba(0, 0, 0, 0.8) !important;
			opacity: 1;
		}

		&::placeholder {
			color: rgba(0, 0, 0, 0.5) !important;
			opacity: 1;
		}

		&:focus {
			background: white !important;
		}
	}
}

.stats-wrapper {
	padding: 10px 125px;
	th {
		text-align: center;
	}
}

#page-wrapper {
	background-color: #f0f0f0;
	margin: 0 !important;
}

th {
	background: #000000 !important;
	color: #ffffff;
	border-right: 1px solid rgba(255, 255, 255, 0.3) !important;
	border-bottom: 0 !important;
}

.sorting_asc,
.sorting,
.sorting_desc {
	// color: white !important;

	&::after {
		opacity: 1 !important;
	}
}

.inactive-sort {
	color: #cccccc;
}

.pagination-btn {
	&:hover {
		color: black;
	}
}

.pagination-active {
	background-color: #000000;
	color: white;

	&:hover,
	&:focus {
		color: white !important;
	}
}

.checkbox-wrapper {
	input[type="checkbox"],
	label {
		cursor: pointer;
	}
}

.mb-0 {
	margin-bottom: 0 !important;
}

.custom-tabs {
	background: white;

	.btn-active {
		border-bottom: 2px solid #000000 !important;
	}

	button {
		text-transform: uppercase;
		margin-bottom: 0 !important;
		cursor: pointer !important;
		border-radius: 0;
		border: 0 !important;
		border-bottom: 2px solid transparent;

		&:hover {
			background: white;
		}

		&:focus {
			background: white;
			border: 0;
			outline: 0;
		}
	}
}

.float-left {
	float: left;
}

.custom-abbr {
	cursor: auto !important;
	border: none !important;
}

body,
.modal {
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
	}

	&::-webkit-scrollbar-thumb {
		background-color: #000000;
		outline: 0;
	}
}

.table-pagination-wrapper {
	position: relative;
	right: 10px;
	text-align: right;
	display: flex;
	justify-content: flex-end;
	.page-count {
		margin-right: 10px;
		line-height: 32px;
	}
}

.control-label {
	margin-bottom: 5px !important;
}

.cursor-pointer {
	cursor: pointer !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mr-10 {
	margin-right: 10px !important;
}

.confirm-icon {
	color: #ed5564;
	font-size: 30px;
	text-align: center;

	i {
		border: 1px solid #ed5564;
		width: 50px;
		border-radius: 50%;
		padding: 10px;
	}
}

.page-heading {
	margin-bottom: 20px;
}

.input-label-wrapper {
	margin-bottom: 10px !important;
}

.profile-wrapper {
	background-color: #fff !important;
	border-radius: 10px;
	padding: 0 10%;
	font-weight: 400;
	color: #000 !important;

	.profile-field {
		padding: 10px;
		height: 30px;
		text-transform: capitalize;
	}

	.profile-value {
		padding: 10px;
	}
}

.float-right {
	float: right !important;
}

.checkbox-wrapper {
	padding-left: 10px;
}

.display-inline-flex {
	display: -webkit-inline-box !important;
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
}

.form-control {
	border: none !important;
	border-bottom: 2px solid #e5e6e7 !important;
}

.form-control:focus {
	border: none !important;
	border-bottom: 2px solid #000000 !important;
}
.login-wrapper {
	height: 100vh;
	width: 100vw;
	.middle-box {
		max-width: 1000px !important;
	}
	.loginscreen.middle-box {
		width: 600px !important;
	}
	.ibox-content {
		margin-top: 189px;
		border: none;
		border-radius: 50px;
		background-color: #ffffff !important;
		input {
			background-color: #ffffff !important;
		}
		h2 {
			color: #000000;
			font-size: 36px;
			font-weight: bold;
			margin-bottom: 40px;
		}
		.btn-primary {
			background-color: #000000 !important;
		}
	}
}

.text-capitalize {
	text-transform: capitalize !important;
}

.action-btn {
	min-width: 40px !important;
	width: 40px !important;
	margin-right: 5px;
	&:hover {
		color: white !important;
	}
}

.custom-error {
	color: red;
	margin-bottom: 0 !important;

	&::first-letter {
		text-transform: uppercase;
	}
}

.mr-2 {
	margin-right: 0.5rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.mb-25 {
	margin-bottom: 25px !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.d-flex {
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
}

.align-items-center {
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
}

.justify-content-between {
	-webkit-box-pack: space-between !important;
	-ms-flex-pack: space-between !important;
	justify-content: space-between !important;
}

.justify-content-end {
	-webkit-box-pack: end !important;
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}

.justify-content-center {
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

body.mini-navbar .user-role-element {
	display: none !important;
}

.mini-navbar-links-wrapper {
	display: none;
	position: absolute;
	top: 0;
	right: -90px;
	width: 160px;
	height: 100%;
	z-index: 1;

	ul {
		width: 100%;

		li {
			width: 100%;
			height: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.collapse-active {
	height: auto !important;
}

body.mini-navbar .navbar-default .nav li {
	cursor: pointer;

	&:hover {
		background-color: #293846;

		.mini-navbar-links-wrapper {
			display: block;

			ul {
				display: block;

				a {
					display: block;
					width: 100%;
					height: 100%;

					&:hover {
						background-color: transparent;
					}
				}
			}
		}
	}

	a {
		text-align: center;

		i {
			margin-right: 0 !important;
		}
	}
}

.profile-element {
	cursor: pointer !important;
	color: #d6d6d6 !important;
	font-weight: 400 !important;
	margin: 0 !important;
}
.pro-user-toggle-li {
	display: inline-flex !important;
	justify-content: flex-end;
	align-items: center;

	.pro-user-toggle-btn {
		width: auto !important;
		display: block;
		font-size: 35px;
		color: #000000;
		min-height: 50px;
		font-weight: 600;
		cursor: pointer;
	}
	span {
		font-size: 16px;
		color: black;
		font-weight: 600;
	}
}
.logout-li {
	margin-right: 0 !important;
	.logout-btn {
		width: auto !important;
		display: block;
		font-size: 35px;
		color: #000000;
		margin-top: 5px;
		min-height: 50px;
		font-weight: 600;
		cursor: pointer;
	}
}
.navbar-right {
	margin-right: 15px;
	li {
		min-width: auto !important;
		padding: 0 15px;
	}
}
.page-body {
	padding-top: 70px;
}
.navbar-wrapper {
	.nav-active {
		background: #000000;
		.nav-item-link {
			color: white !important;
		}
	}
	button.nav-active.dropbtn {
		color: #fff;
	}
	li {
		min-width: 140px;
		text-align: center;
	}
	max-height: 70px;
	position: fixed;
	z-index: 2000;
	width: 100vw;
	.navbar-static-top {
		margin-bottom: 0 !important;
		max-height: 70px;
		.language-dropdown {
			margin-right: 10px;
		}
		.nav-item-link {
			color: #676a6c;
			font-size: 17px;
		}
	}
}

.nav-label {
	text-transform: capitalize !important;
}

.breadcrumb-btn {
	margin-top: 28px;
	float: right;

	.back-btn {
		color: #fff !important;
	}

	button {
		font-weight: 700 !important;
	}
}

.modal-close-btn {
	padding: 0;
	position: absolute;
	right: 10px;
	z-index: 1;
	background-color: transparent;
	border: none;
	outline: 0;
	font-size: 20px;

	&:hover,
	&:focus,
	&:active {
		border: none;
		background-color: transparent;
	}
}

.modal-footer-area {
	border: none !important;
}

.text-align-center {
	text-align: center !important;
}

.font-20 {
	font-size: 20px;
}

.modal-body {
	padding-top: 35px !important;
}

.width-100px {
	width: 100px;
}

.height-34px {
	height: 34px;
}

.react-datepicker-wrapper {
	display: block !important;

	input {
		margin-left: 0;
	}
}

.mini-navbar {
	.profile-dropdown {
		left: 48px !important;
		top: 45px !important;
		z-index: 2;
	}
}

.m-t-xs {
	margin-top: 5px;
}

.fadeInRight {
	-webkit-animation-name: fadeInRight;
	animation-name: fadeInRight;
}

.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

@media (min-width: 768px) {
	.confirm-wrapper {
		.modal-dialog {
			width: 435px;
			margin: 30px auto;
		}
	}
}

.checkbox-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;

	.checkbox-label {
		display: block;
		position: relative;
		padding-left: 35px;
		cursor: pointer;
		font-size: 14px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		min-height: 20px;
		line-height: 20px;
		margin-bottom: 15px;
		margin-right: 15px;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;

			&:checked ~ .checkmark {
				background-color: #000000 !important;

				&:after {
					display: block;
				}
			}
		}

		.checkmark {
			position: absolute;
			top: 1px;
			left: 0;
			width: 20px;
			height: 20px;
			background-color: #eee;

			&::after {
				content: "";
				position: absolute;
				display: none;
				left: 7px;
				top: 3px;
				width: 5px;
				height: 10px;
				border: solid white;
				border-width: 0 2px 2px 0;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}

		&:hover {
			input ~ .checkmark {
				background-color: #ccc;
			}
		}
	}
}

.react-datepicker__current-month {
	font-size: 13px !important;
}

.react-datepicker__day--selected {
	background-color: #3ab394 !important;
}

.react-datepicker__day--today {
	color: #000 !important;
	background-color: #fff;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	font-size: 12px !important;
}

.datepicker-inline-wrapper {
	.datepicker-input-wrapper {
		label {
			margin-right: 10px;
		}

		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
}

button:disabled {
	cursor: not-allowed !important;
}

.language-dropdown {
	width: 100px;
	margin: 0 auto 30px;
}
.form {
	padding: 20px 10px;
}
.kml-preview {
	position: relative;
	background-color: #e7e7e7;
	width: 100px;
	height: 100px;
	.fa-file {
		justify-content: center;
		position: relative;
		top: 25%;
		font-size: 50px;
		left: 25%;
	}
	.fa-minus {
		position: absolute;
		right: 0;
		background-color: grey;
		color: white;
		border-radius: 10px;
		font-size: 20px;
		padding: 0 5px;
		cursor: pointer;
	}
}
.hidden {
	display: none;
}
.fileicon {
	i {
		font-size: 25px;
		color: grey;
	}
}
.back-btn {
	min-width: 120px;
}
.traversal-list {
	.modal-dialog {
		min-width: 95vw !important;
	}
}
.tab-container {
	.tab {
		min-width: 150px;
		text-transform: uppercase;
		background: #000000;
		color: white;
		font-size: 15px;
		padding: 5px 10px;
		margin-right: 10px;
		cursor: pointer;
	}
	.tab-active {
		background: #167728 !important;
	}
}
.traversal-title {
	// text-transform: capitalize;
	margin-left: 20px;
	margin-top: 40px;
	font-size: 15px;
	color: black;
}
th {
	i {
		line-height: 6px;
	}
}
.btn-add {
	min-height: 36px;
}
.center-tabs {
	.tab {
		min-height: 36px;
		line-height: 28px;
		text-align: center;
		min-width: 100px;
		margin-right: 1px !important;
	}
	.tab-active {
		background: #000 !important;
		border-bottom: 5px solid red;
	}
}
.config-wrapper {
	width: 100%;
	button {
		position: absolute;
		right: 23px;
	}
	.config-detail {
		font-size: 15px;
	}
	.config-label {
		color: black !important;
		margin-right: 20px !important;
		font-size: 15px !important;
		background-color: #d1dade;
		font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-weight: 600;
		padding: 3px 8px;
		text-shadow: none;
		display: inline;
		padding: 0.2em 0.6em 0.3em;
		font-size: 75%;
		font-weight: 700;
		line-height: 1;
		color: #fff;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		border-radius: 0.25em;
	}
}
.logo {
	width: 20px;
	height: 20px;
}

/*dropdown menu*/
.dropbtn {
	//background-color: #04AA6D;
	color: #676a6c;
	padding: 20px 33px;
	font-size: 17px;
	font-weight: 600;
	border: none;
}

.dropdown {
	position: relative;
	display: inline-block;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f1f1f1;
	min-width: 160px;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
	z-index: 1;
	color: #676a6c;
}

.dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
}

.dropdown-content a:hover {
	background-color: #ddd;
}

.dropdown:hover .dropdown-content {
	display: block;
	color: #676a6c;
}

.dropdown:hover .dropbtn {
	background-color: black;
}

.show-center-toggle-btn {
	width: auto !important;
	display: block;
	font-size: 35px;
	color: #000000;
	min-height: 50px;
	font-weight: 600;
	cursor: pointer;
}
