.header-text {
	font-size: 18px;
	color: black;
	font-weight: bold;
	// margin-top: 30px;
	margin-left: 11px;
	margin-bottom: -7px;
}
.dashboard-pointer {
	cursor: pointer;
}
.stati {
	background: #fff;
	height: 117px;
	padding: 1em;
	margin: 10px 0;
	-webkit-transition: margin 0.5s ease, box-shadow 0.5s ease; /* Safari */
	transition: margin 0.5s ease, box-shadow 0.5s ease;
}

.bg-transparent {
	background: transparent !important;
}

.stati div {
	width: calc(100% - 3.5em);
	display: block;
	float: right;
	text-align: right;
}
.stati div b {
	font-size: 2.2em;
	width: 100%;
	padding-top: 0px;
	margin-top: -0.2em;
	margin-bottom: -0.2em;
	display: block;
}
.stati div span {
	font-size: 18px;
	width: 100%;
	display: block;
}

.stati.left div {
	float: left;
	text-align: left;
}

.stati.black {
	color: #000000;
}
.stati.turquoise {
	color: #23c6c8;
}
.stati.emerald {
	color: rgb(46, 204, 113);
}
.stati.peter_river {
	color: rgb(52, 152, 219);
}
.stati.amethyst {
	color: rgb(155, 89, 182);
}
.stati.wet_asphalt {
	color: rgb(52, 73, 94);
}
.stati.green_sea {
	color: rgb(22, 160, 133);
}
.stati.nephritis {
	color: rgb(39, 174, 96);
}
.stati.belize_hole {
	color: rgb(41, 128, 185);
}
.stati.wisteria {
	color: rgb(142, 68, 173);
}
.stati.midnight_blue {
	color: rgb(44, 62, 80);
}
.stati.sun_flower {
	color: rgb(241, 196, 15);
}
.stati.carrot {
	color: rgb(230, 126, 34);
}
.stati.alizarin {
	color: rgb(231, 76, 60);
}
.stati.clouds {
	color: rgb(236, 240, 241);
}
.stati.concrete {
	color: rgb(149, 165, 166);
}
.stati.orange {
	color: rgb(243, 156, 18);
}
.stati.pumpkin {
	color: rgb(211, 84, 0);
}
.stati.pomegranate {
	color: rgb(192, 57, 43);
}
.stati.silver {
	color: rgb(189, 195, 199);
}
.stati.asbestos {
	color: rgb(127, 140, 141);
}

.p-0-10 {
	padding: 0 11px;
}

.paid-platform-user-card {
	width: 15%;
}
.monthly-subscription-card {
	width: 25%;
}
.centers-routes-card {
	width: 33.33%;
}
.test-traversals-card {
	width: 17%;
}
.learning-traversals-card {
	width: 17%;
}
